import React from "react";
import "./ForPatient.css";

export default function ForPatient() {

    return (
        <div className="ForPatient">
            <h1>Informacje dla Pacjentów</h1>

            <p>Ważne informacje dla pacjentów/klientów:</p>
            <ul>
                <li>Upnij długie włosy (masaże karku, pleców).</li>
                <li>Zdejmij biżuterię (łańcuszki, duże kolczyki itp.).</li>
                <li>Załóż luźną bieliznę lub skorzystaj z naszej jednorazowej bielizny (masaże relaksacyjne).</li>
                <li>Zmyj makijaż, aby cieszyć się dłuższym masażem twarzy.</li>
                <li>Masaż Kobido obejmuje także obszar skóry głowy (dlatego nie upinaj włosów) , co wiąże się ze zburzeniem fryzury i nieznacznym naolejowaniem włosów.</li>
                <li>Zarezerwuj nieco czasu po masażu, aby odpocząć.</li>
                <li>Zadbaj o ciepły ubiór po masażu (w chłodniejsze dni).</li>
            </ul>

            <p>Reakcje miejscowe, które mogą pojawić się po masażu:</p>
            <ul>
                <li>rozgrzanie masowanych tkanek</li>
                <li>przekrwienie i zaczerwienienie</li>
                <li>uczucie rozluźnienia lub wzrost napięcia mięśni</li>
                <li>nasilenie dolegliwości bólowych lub zmniejszenie dolegliwości bólowych</li>
                <li>nadwrażliwość na dotyk</li>
                <li>mrowienie, drętwienie</li>
            </ul>

            <p>Niepożądane reakcje ogólne organizmu, które mogą wystąpić w trakcie lub po zakończeniu masażu:</p>
            <ul>
                <li>wzrost lub obniżenie ciśnienia tętniczego</li>
                <li>rozdrażnienie, zmęczenie</li>
                <li>zawroty głowy, duszność</li>
                <li>senność lub bezsenność</li>
                <li>zwiększenie dolegliwości bólowych po zabiegu</li>
                <li>uczucie zimna po zabiegu</li>
                <li>uczucie pobudzenia po zabiegu</li>
            </ul>

            <p>Zalecenia dla pacjenta wspomagające proces rehabilitacji:</p>
            <ul>
                <li>unikanie przegrzewania oraz ochładzania masowanej okolicy</li>
                <li>unikanie nadmiernego przeciążania opracowywanych mięśni bądź kręgosłupa</li>
                <li>rozciąganie opracowanych mięśni i umiarkowane, sukcesywne obciążanie celem ich wzmocnienia</li>
                <li>regularna aktywność fizyczna, np. spacery, Nordic Walking, jazda na rowerze, pływanie itp.</li>
                <li>dbanie o prawidłową masę ciała</li>
                <li>w przypadku kręgosłupa: unikanie przyjmowania długotrwałej pozycji siedzącej lub stojącej, unikanie gwałtownych ruchów w obrębie kręgosłupa, unikanie sytuacji stwarzających ryzyko urazu w obrębie kręgosłupa</li>
            </ul>

            <p>Przeciwwskazania ogólne do zabiegu masażu:</p>
            <ul>
                <li>gorączka</li>
                <li>złe samopoczucie</li>
                <li>nieleczone nadciśnienie tętnicze</li>
                <li>niewyrównane wady serca (przede wszystkim III i IV stopień NYHA)</li>
                <li>zaburzenia rytmu serca, typu bradykardia, tachykardia, dodatkowe skurcze serca (względne</li>
                <li>zaburzenia krzepliwości krwi</li>
                <li>krwawienia, krwotoki lub możliwość ich wystąpienia</li>
                <li>menstruacja</li>
                <li>wczesne stany po zawale serca</li>
                <li>ciąża zagrożona</li>
                <li>tętniaki</li>
                <li>miażdżyca</li>
                <li>stany nowotworowe (względne)</li>
                <li>ostre stany zapalne</li>
                <li>choroby zakaźne</li>
                <li>choroby o podłożu bakteryjnym, wirusowym i pasożytniczym</li>
                <li>hemofilia</li>
                <li>skaza naczyniowa (tendencja do pękania naczyń)</li>
                <li>zbyt krótki okres po podanych blokadach</li>
            </ul>

            <p>Przeciwwskazania miejscowe do zabiegu masażu:</p>
            <ul>
                <li>przerwanie ciągłości tkanek (skóry) w miejscu masażu</li>
                <li>zmiany dermatologiczne, zmiany skórne o charakterze zapalnym i troficznym z owrzodzeniami, pęcherzami i zmianami ropnymi</li>
                <li>nadwrażliwość na dotyk (przeczulica) lub zmniejszenie odczucia dotyku (niedoczulica)</li>
                <li>obrzęk</li>
                <li>krwiak</li>
                <li>niepełny zrost kostny</li>
                <li>duża bolesność</li>
                <li>zapalenie żył, zakrzepy, żylaki, ostra zakrzepica żył kończyn dolnych i miednicy</li>
                <li>stany po przebytym zakrzepowym zapaleniu żył (oderwanie zakrzepu od ściany naczynia może być śmiertelne; pół roku po przebytym zakrzepowym zapaleniu żył można stosować masaż, ale NIGDY w miejscu zakrzepów)</li>
                <li>zmiany i zbliznowacenia po naświetlaniach</li>
            </ul>

            <p>Przykładowe choroby, przy których nie stosuje się zabiegów masażu:</p>
            <ul>
                <li>gruźlica</li>
                <li>zapalenie płuc, oskrzeli</li>
                <li>dychawica oskrzelowa w czasie napadów</li>
                <li>pęknięcie wrzodu żołądka i wrzodu dwunastnicy</li>
                <li>zapalenie trzustki</li>
                <li>wirusowe zapalenie wątroby</li>
                <li>zapalenie pęcherzyka żółciowego</li>
                <li>stany zapalne dróg żółciowych</li>
                <li>zapalenie wyrostka robaczkowego</li>
                <li>zapalenie otrzewnej</li>
                <li>zmiany grożące przerwaniem ciągłości jelit</li>
                <li>stan po przebytym zawale serca (dwa tygodnie)</li>
                <li>zaawansowana miażdżyca naczyń wieńcowych czy obwodowych</li>
                <li>świeże stany zapalne zastawek i serca</li>
                <li>dusznica bolesna w czasie napadu</li>
                <li>choroby naczyń obwodowych</li>
                <li>choroba Buergera III° IV°</li>
                <li>choroba Reunalda III° IV°</li>
                <li>gruźlica nerek</li>
                <li>nerczyca</li>
                <li>ostre zapalenie pęcherza moczowego</li>
                <li>ostre zapalenie moczowodu</li>
                <li>odmiedniczkowe zapalenie nerek</li>
                <li>kamica nerkowa (duży kamień)</li>
                <li>gruźlica kości</li>
                <li>świeży zrost kostny</li>
                <li>zaawansowana osteoporoza</li>
                <li>RZS, ZZSK w okresie zaostrzenia</li>
                <li>zapalenie opon mózgowych</li>
                <li>krwiaki, tętniaki i naczyniaki układu nerwowego</li>
                <li>jamistość rdzenia</li>
                <li>zapalenie szpiku z przetokami</li>
                <li>drżączka porażenna</li>
                <li>epilepsja (zależy od stanu chorobowego)</li>
                <li>ostre stany zapalenie nerwów obwodowych.</li>
            </ul>
        </div>
    )
}